export const environment = {
  production: true,
  version: '',
  versionHash: '3f8cd8211e',
  techcards: {
    deviationPercentage: 0.1,
  },
  ICE_SERVERS: {
    URLS: 'stun:stun.l.google.com:19302',
    USERNAME: 'itorum-user',
    CREDENTIAL: 'xu2kiaweiroz5Jo',
    CREDENTIAL_TYPE: 'password'
  }
};
