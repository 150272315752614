import { IUser } from "./user";

export interface IControlChatEnableTranslateBody {
  control: string;
  user: Pick<IUser, 'id' | 'fullName'>;
  room: number;
  uid: string;
  target: ChatLangs;
}

export enum ChatLangs {
  RUSSIAN = 'ru',
  ENGLISH = 'en',
  GERMAN = 'de',
  SPANISH = 'es',
  ITALIAN = 'it',
  CHINESE = 'zh-CN',
  SWEDISH = 'sv'
}

export enum FlacDecoderLocales {
  RUSSIAN = 'ru-RU',
  ENGLISH = 'en-US',
  GERMAN = 'de-DE',
  SPANISH = 'es-ES',
  ITALIAN = 'it-IT',
  CHINESE = 'zh-CN',
  SWEDISH = 'sv-SE'

}
