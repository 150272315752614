export const themes = {
  base : {
    '--primary-color' : '#71bf44',
    '--primary-color-lighten-5' : '#78c24e',
    '--primary-color-lighten-10' : '#80c657',
    '--primary-color-lighten-20' : '#8ecc6b',
    '--primary-color-darken-5' : '#6bb73f',
    '--primary-color-darken-10' : '#65ae3b',
    '--primary-color-darken-20' : '#5a9a35',
    '--light-color': '#879d7f',
    '--light-color-backgorund': '#f2f5f1',
    '--secondary-color-x1': '#8ebb53',
    '--secondary-color-x2': '#f8fbf5',
    '--secondary-color-x3': '#9fb199',
    '--secondary-color-x4': '#c9d9bf',
    '--text-color': '#ffffff',
    '--logo-background': '#515151',
    '--display-footer': 'block',
    '--login-logo-width': '400px',
  },
  gpn: {
    '--primary-color' : '#0070ba',
    '--primary-color-lighten-5' : '#0076c3',
    '--primary-color-lighten-10' : '#007bcd',
    '--primary-color-lighten-20' : '#0086df',
    '--primary-color-darken-5' : '#006ab1',
    '--primary-color-darken-10' : '#0065a7',
    '--primary-color-darken-20' : '#005a95',
    '--light-color': '#11517d',
    '--light-color-backgorund': '#eff6fb',
    '--secondary-color-x1': '#f0f7fb',
    '--secondary-color-x2': '#f0f7fb',
    '--secondary-color-x3': '#397ba0',
    '--secondary-color-x4': '#70a3bf',
    '--text-color': '#ffffff',
    '--logo-background': '#0070ba',
    '--display-footer': 'none',
    '--login-logo-width': '600px',
  },
  lanit : {
    '--primary-color' : '#000db1',
    '--primary-color-lighten-5' : '#0078d7',
    '--primary-color-lighten-10' : '#0078d7',
    '--primary-color-lighten-20' : '#0078d7',
    '--primary-color-darken-5' : '#14bfe9',
    '--primary-color-darken-10' : '#14bfe9',
    '--primary-color-darken-20' : '#14bfe9',
    '--light-color': '#000db1',
    '--light-color-backgorund': '#e5f1fb',
    '--secondary-color-x1': '#7dbaeb',
    '--secondary-color-x2': '#e5f1fb',
    '--secondary-color-x3': '#0078d7',
    '--secondary-color-x4': '#7dbaeb',
    '--text-color': '#ffffff',
    '--logo-background': '#515151',
    '--display-footer': 'block',
    '--login-logo-width': '300px',
  },
  promoil: {
    '--primary-color': '#f48c30',
    '--primary-color-lighten-5' : '#78c24e',
    '--primary-color-lighten-10' : '#80c657',
    '--primary-color-lighten-20' : '#8ecc6b',
    '--primary-color-darken-5' : '#6bb73f',
    '--primary-color-darken-10': '#ed7408',
    '--primary-color-darken-20' : '#5a9a35',
    '--light-color': '#879d7f',
    '--light-color-backgorund': '#f2f5f1',
    '--secondary-color-x1': '#8ebb53',
    '--secondary-color-x2': '#f8fbf5',
    '--secondary-color-x3': '#9fb199',
    '--secondary-color-x4': '#c9d9bf',
    '--text-color': '#ffffff',
    '--logo-background': '#515151',
    '--display-footer': 'block',
    '--login-logo-width': '300px',
  },
  develit : {
    '--primary-color' : '#5fc7c9',
    '--primary-color-lighten-5' : '#7ed2d3',
    '--primary-color-lighten-10' : '#B8D3D0',
    '--primary-color-lighten-20' : '#cbd7d5',
    '--primary-color-darken-5' : '#4c9fa0',
    '--primary-color-darken-10' : '#397778',
    '--primary-color-darken-20' : '#234a4a',
    '--light-color': '#d8e1d4',
    '--left-menu-back': '#666666',
    '--light-color-backgorund': '#f1f5f5',
    '--secondary-color-x1': '#53b8bb',
    '--secondary-color-x2': '#f5fbfa',
    '--secondary-color-x3': '#99b1ae',
    '--secondary-color-x4': '#bfd9d6',
    '--text-color': '#ffffff',
    '--logo-background': '#666666',
    '--display-footer': 'block',
    '--login-logo-width': '400px',
  },
};
