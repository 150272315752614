import { ElementRef, Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, ReplaySubject, Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { IPublisher, IVolumeUnit } from "@src/app/+conference/pages/room/components/util";
import {
  ToastrInviteDialogComponent
} from "@src/app/common/services/comonents/toastr-invite-dialog/toastr-invite-dialog.component";
import { IJanusUser } from "@src/app/+conference/models/janus-user";
import {
  ToastrCallDialogComponent
} from "@src/app/common/services/comonents/toastr-call-dialog/toastr-call-dialog.component";
import { uid } from "uid";
import {
  ToastrDoCallDialogComponent
} from "@src/app/common/services/comonents/toastr-do-call-dialog/toastr-do-call-dialog.component";
import { Router } from "@angular/router";
import { AppConfigService } from "@shared/services/app-config.service";
import { DOCUMENT } from "@angular/common";
import { JanusJS } from "janus-gateway/npm";
import PluginHandle = JanusJS.PluginHandle;
import { RoomStateService } from "app/+conference/pages/room/state/room-state.service";
import {
  ChatLangs,
  IControlChatEnableTranslateBody,
  IControlChatMessageBody,
  IControlChatSendMessageBody,
  IFeed,
  IControlChatSendVoiceMessageBody
} from "@itorum/models";
import { CommonStateService } from "app/common/state/common-state.service";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
import { PublisherComponent } from "app/+conference/pages/room/components/publisher/publisher.component";

// https://medium.com/@clintpitzak/how-to-use-external-javascript-libraries-in-angular-8-247baacbdccf
declare let Janus: any;

interface IInitCall {
  uid?: string;
  ack?: unknown;
  user?: unknown;
  name?: string;
}

@Injectable({
  providedIn: "root"
})
export class JanusService {
  // private url = 'wss://janus-demo.itorum-mr.ru/remote-expert';


  private url!: string;
  private room = 1;

  get currentRoom(): number {
    return this.room;
  }

  private opaqueId = `itr_videoroom-${Janus.randomString(12)}`;
  private janus: any;
  private sfutest!: PluginHandle;
  private username!: string;

  /**
   * current audio settings
   */
  local: any = {
    audioContext: null,
    audioSource: null,
    scriptNode: null,
    audioAnalyser: null,
    volume: 0
  };

  /**
   * key is publisher_id = rfid = user_id
   * @private
   */
  private feeds = new Map<number, IFeed>();

  private my_id!: number;
  private my_private_id!: number;
  //   remoteFeed.send({ message: subscribe });
  private joinStatus!: string;

  private sharedScreen = false;
  private isJanusInitted = false;

  /**
   * Current shown toast dialog
   * @private
   */
  private activeToast: any;

  // private _onIncomingCallCancelled$ = new BehaviorSubject<boolean>(false);
  private _onIncomingCallCancelled$ = new Subject<boolean>();
  // private readonly MAX_PUBLISHERS = 20;
  private bell: HTMLAudioElement;

  private canvasStreamSender!: RTCRtpSender;
  private canvasStream: any;

  /**
   * Признак нажатия кнопки запроса картинки пользователем
   */
  reqPicLock = false;

  private _imageLoadingProgress$ = new Subject<number>();

  /**
   * Lock when share, and unlock when stop
   * @private
   */
  private isShareCanvasAlready = false;
  private invite_call: string;
  private destroy$ = new Subject();
  private callingLabel = "";
  uid!: string; // необходимо передавать как часть ссылки для приглашения, в формате https://domain.itorummr.com/invite/uid
  private accepted_room_id!: number;
  private callerId!: number;
  private _someVideoStateChanges$ = new Subject<any>();
  /**
   * Видео тэг для откреплённого звонка
   * @private
   */
  private video_tag: ElementRef;
  private nowCenteredFeed: any;
  get _nowCenteredFeed() {
    return this.nowCenteredFeed;
  }
  private nowInAttachSaveFeed: any;
  get _nowInAttachSaveFeed() {
    return this.nowInAttachSaveFeed;
  }
  private isMuteState: boolean;

  get someVideoStateChanges$(): Observable<any> {
    return this._someVideoStateChanges$.asObservable();
  }

  get imageLoadingProgress$(): Observable<number> {
    return this._imageLoadingProgress$.asObservable();
  }

  private _isImageLoadingProgress$ = new Subject<boolean>();
  get isImageLoadingProgress$(): Observable<boolean> {
    return this._isImageLoadingProgress$.asObservable();
  }


  get onIncomingCallCancelled$(): Observable<boolean> {
    return this._onIncomingCallCancelled$.asObservable();
  }

  private _localVolume$ = new Subject<any>();
  get localVolume$(): Observable<any> {
    return this._localVolume$.asObservable();
  }

  // private _onIncomingCallRejected$ = new BehaviorSubject<boolean>(false);
  private _onIncomingCallRejected$ = new Subject<boolean>();
  get onIncomingCallRejected$(): Observable<boolean> {
    return this._onIncomingCallRejected$.asObservable();
  }

  get isNowShared(): boolean {
    return this.sharedScreen;
  }

  /**
   * local audio/video stream
   */
  private myStream: any;
  private myVideo!: ElementRef;
  private publishers!: IPublisher[];
  /**
   * List of rooms on server
   */
  private rooms: any;
  private _rooms$ = new BehaviorSubject<any[]>([]);

  private elSharedScreen: ElementRef[] = []; // only one item !!!
  get rooms$(): Observable<any[]> {
    return this._rooms$.asObservable();
  }

  private _isSfuTestObtained = new ReplaySubject<boolean>(1);
  get isSfuTestObtained(): Observable<boolean> {
    return this._isSfuTestObtained.asObservable();
  }

  private _isJanusInstanceCreated = new ReplaySubject<boolean>(1);
  get isJanusInstanceCreated(): Observable<boolean> {
    return this._isJanusInstanceCreated.asObservable();
  }

  /**
   * List of rooms on server online
   */
  private roomsOnline: any;
  private _roomsOnline$ = new BehaviorSubject<any[]>([]);

  get roomsOnline$(): Observable<any[]> {
    return this._roomsOnline$.asObservable();
  }

  private profile = { username: "", fullName: "" };
  private _profile$ = new Subject<{ username: string, fullName: string }>();
  get profile$() {
    return this._profile$.asObservable();
  }

  /**
   * users online on server
   */
  private online!: IJanusUser[];

  /**
   * users online in portal
   * @private
   */
  private _online$ = new BehaviorSubject<IJanusUser[]>([]);

  get online$(): Observable<IJanusUser[]> {
    return this._online$.asObservable();
  }

  /**
   * some feeds
   * @deprecated
   */
  private containers: any = {
    mobile: [],
    desktop: []
  };
  /**
   * current parameters of call
   */
  private callStore: { currentRoom: any, autoJoin: any } = {
    currentRoom: undefined,
    autoJoin: undefined
  };

  private usersInRoom = {};

  private initCall: IInitCall = {};
  /**
   * @deprecated
   * @private
   */
  private invites = {};

  get muted(): Observable<boolean> {
    return this._muted.asObservable();
  }

  private _muted = new BehaviorSubject<boolean>(true);

  // private _feeds_volumes$ = new BehaviorSubject<IVolumeUnit>({rfindex: -1 , volume: 0});
  private _feeds_volumes$ = new Subject<IVolumeUnit | null>();
  get feeds_volumes$(): Observable<IVolumeUnit | null> {
    return this._feeds_volumes$.asObservable();
  }

  private _publishers$ = new Subject<IPublisher[]>();

  get publishers$(): Observable<IPublisher[]> {
    return this._publishers$.asObservable();
  }

  // private _new_publisher_connected$ = new BehaviorSubject<IPublisher[]>(null);
  private _new_publisher_connected$ = new Subject<IPublisher[]>();

  get new_publisher_connected$(): Observable<IPublisher[]> {
    return this._new_publisher_connected$.asObservable();
  }

  // private _feeds_updated = new BehaviorSubject<Map<number, any>>(new Map<number, any>()); // init all subscribers
  private _feeds_updated = new Subject<Map<number, any>>();

  /**
   * Используется, когда добавляется новый фид, удаляется один из фидов или при полной очистке фидов
   */
  get feeds_updated$(): Observable<Map<number, any>> {
    return this._feeds_updated.asObservable();
  }

  // private _publisher_unpublished$ = new BehaviorSubject<any>(null);
  private _publisher_unpublished$ = new Subject<any>();

  get publisher_unpublished$(): Observable<any> {
    return this._publisher_unpublished$.asObservable();
  }

  private _releaseSharedScreen$ = new BehaviorSubject<any>(null);

  get releaseSharedScreen$(): Observable<void> {
    return this._releaseSharedScreen$.asObservable();
  }

  private _myStream$ = new Subject<any>();
  get myStream$(): Observable<any> {
    return this._myStream$.asObservable();
  }

  // private _onInviteRejected$ = new BehaviorSubject<IJanusUser>({id: -1, fullName: 'nuff user', roles: []});
  private _onInviteRejected$ = new Subject<IJanusUser>();
  get onInviteRejected$(): Observable<IJanusUser> {
    return this._onInviteRejected$.asObservable();
  }

  // private _onInviteAccepted$ = new BehaviorSubject<IJanusUser>({id: -1, fullName: 'nuff user', roles: []});
  private _onInviteAccepted$ = new Subject<IJanusUser>();
  get onInviteAccepted$(): Observable<IJanusUser> {
    return this._onInviteAccepted$.asObservable();
  }

  constructor(
    private toastrService: ToastrService,
    private appConfigService: AppConfigService,
    private router: Router,
    private roomStateService: RoomStateService,
    @Inject(DOCUMENT) private document: HTMLDocument,
    private commonStateService: CommonStateService,
    private translateService: TranslateService
  ) {
    // Janus.log('JANUS', Janus);
    // console.log("Janus.isWebrtcSupported() => ", Janus.isWebrtcSupported());
    const src = this.appConfigService.appConfig.bellSrc || "assets/media/bell.mp3";
    // console.log('src =>>', src);
    this.bell = new Audio();
    // console.log('bell =>>', this.bell);
    this.bell.src = src;
    try {
      this.bell.load();
      this.bell.loop = true;
    } catch (e) {
      console.error(e);
    }
    this.translateService.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => {
        this.invite_call = this.translateService.instant("common.toastr-invite-dialog.invite_call");
        this.callingLabel = this.translateService.instant("common.toastr-do-call-dialog.calling_label");
      });
    this.invite_call = this.translateService.instant("common.toastr-invite-dialog.invite_call");
    this.callingLabel = this.translateService.instant("common.toastr-do-call-dialog.calling_label");
  }

  initJanus(username: string, accessToken: string, debug: boolean = false) {
    // console.log("-== TRY TO INIT JANUS ==-", this.janus);
    if (this.janus) {
      // console.log("=== JANUS ALREADY INITIATED ===");
      return;
    }

    this.url = this.makeUrl(accessToken);
    this.username = username;
    // this.myVideo = myvideo;

    if (this.isJanusInitted) {
      // console.log("=== JANUS ONLY NEW INSTANCE ===");
      this.janus = this.newJanusInstance();
    } else {
      // console.log("=== JANUS FIRST TIME INIT ===");
      Janus.init({
        debug: debug,
        callback: () => {
          this.janus = this.newJanusInstance();
          this.isJanusInitted = true;
        }
      });
    }
  }

  newJanusInstance(): any {
    // console.log("app-settings.json ICE_SERVERS vars =>", this.appConfigService.appConfig?.ICE_SERVERS);
    const iceServersConfig = {
      urls: this.appConfigService.appConfig.ICE_SERVERS?.URLS || "stun:stun.l.google.com:19302",
      username: this.appConfigService.appConfig.ICE_SERVERS?.USERNAME || "itorum-user",
      credential: this.appConfigService.appConfig.ICE_SERVERS?.CREDENTIAL || "xu2kiaweiroz5Jo",
      credentialType: this.appConfigService.appConfig.ICE_SERVERS?.CREDENTIAL_TYPE || "password"
    };
    // console.log("iceServers config =>", iceServersConfig);
    return new Janus({
      server: this.url,
      iceServers: [iceServersConfig],
      success: (conf: any) => {
        Janus.log("conf => ", conf);
        this.attachVideoroomPlugin();
        this._isJanusInstanceCreated.next(true);
      },
      error: (cause: any) => {
        // Error, can't go on...
        Janus.log("error", cause, this.janus);
      },
      destroyed: () => {
        // I should get rid of this
        Janus.log("destroyed", this.janus);
      }
    });
  }

  /**
   *
   * @param event
   * @param callback
   */
  private onAudioProcess(event: any, callback?: any): { volume: number } {

    const input = event.inputBuffer.getChannelData(0);

    let i;

    let sum = 0.0;

    for (i = 0; i < input.length; ++i) {
      sum += input[i] * input[i];
    }

    return {
      volume: Math.sqrt(sum / input.length) * 500
    };
  }

  private getPublishers(msg: any) {
    console.log("-== GET PUBLISHERS IN ROOM ==-", msg.publishers);
    // this.publishers = msg.publishers?.length ? msg.publishers : this.mock_publishers;
    this.publishers = msg.publishers;
    this._publishers$.next(this.publishers);
  }

  private newPublisherConnected(msg: any) {
    console.log("-== NEW PUBLISHER CONNECTED ==-", msg.publishers);
    this.publishers.push(...msg.publishers);
    // this._publishers$.next(this.publishers);
    this._new_publisher_connected$.next(msg.publishers); // array with only 0 index
  }

  public publishOwnFeed(options: any = {}) {
    // console.log("Publish our stream => ", options);

    // $('#publish').attr('disabled', true).unbind('click');

    const media = this.getMedia({ ...options });

    if (options.canvas) { // if canvas is present, then  no need media in offer

      // console.log("NO media field passed: options.canvas ==>", options.canvas);

      this.sfutest.createOffer({
        iceRestart: false,
        success: (jsep: any) => {
          Janus.log("Got publisher SDP!", jsep);

          this.sharedScreen = options.capture;

          const publish = {
            request: "configure",
            bitrate: 0,
            audio: true,
            video: options.capture
          };

          this.sfutest.send({ message: publish, jsep });
        },
        error: (error: Error) => {
          Janus.error("WebRTC error:", error);
        }
      });
    } else {
      this.sfutest.createOffer({
        iceRestart: false,
        media,
        success: (jsep: any) => {
          Janus.log("Got publisher SDP!", jsep);

          this.sharedScreen = options.capture;

          const publish = {
            request: "configure",
            bitrate: 0,
            audio: true,
            video: options.capture
          };

          this.sfutest.send({ message: publish, jsep });
        },
        error: (error: any) => {
          Janus.error("WebRTC error:", error);
        }
      });
    }

  }

  private publisherLeaving(publisher_id: number) {
    Janus.log("publisherLeaving invoked publisher_id:", publisher_id);
    // console.log('before filtering publisherLeaving publishers', this.publishers);
    const getById = (p: any) => p.id === publisher_id;
    const filterById = (p: any) => p.id !== publisher_id;
    const quited = this.publishers.find(getById);
    this.publishers = this.publishers.filter(filterById);
    // console.log('filtered publisherLeaving publishers', this.publishers);
    this.releaseFeedByPublisher(publisher_id, true);
    this._publisher_unpublished$.next(quited);
  }

  private publisherUnpublished(publisher_id: number) {
    Janus.log("publisherUnpublished invoked publisher_id:", publisher_id);
    // Janus.log('before filtering', this.publishers);
    const getById = (p: any) => p.id === publisher_id;
    const filterById = (p: any) => p.id !== publisher_id;
    const quited = this.publishers.find(getById);
    this.publishers = this.publishers.filter(filterById);
    // Janus.log('filtered publisherUnpublished publishers', this.publishers);
    // this._publishers$.next(this.publishers);
    this.releaseFeedByPublisher(publisher_id);
    this._publisher_unpublished$.next(quited);
  }

  /**
   * creates new remote feed by publisher
   * @param id - publisher id
   * @param display - publisher info (username, client type, FIO, etc.)
   * @param audio {string} - audio codec
   * @param video {string} - video codec
   * @param tag {HTMLVideoElement} - html video tag
   * @param comp {PublisherComponent} - component for save feed into
   */
  newremoteFeed(id: number, display, audio: string, video: string, tag: HTMLVideoElement, comp?: PublisherComponent) {
    Janus.log("newremoteFeed for tag=> ", tag);

    if (!this.janus) {
      console.error("no janus instance found, exit");
      return;
    }

    let remoteFeed: IFeed;
    this.janus.attach({
      plugin: "janus.plugin.videoroom",
      opaqueId: this.opaqueId,
      success: (pluginHandle: PluginHandle) => {
        console.group("Success");
        Janus.log("newRemoteFeedSuccess id=>", id, " video =>", video);
        Janus.log("pluginHandle", pluginHandle);
        Janus.log("remoteFeed", remoteFeed);

        remoteFeed = <IFeed>pluginHandle;
        if (comp) {
          comp.feed = remoteFeed; // сохранить фид в компоненте
        }
        // ==== imported code ===
        remoteFeed.simulcastStarted = false;
        remoteFeed.mediaStream = new MediaStream();
        remoteFeed.audioContext = new AudioContext();
        remoteFeed.audioSource = null;
        remoteFeed.scriptNode = null;
        remoteFeed.volume = 0;
        // ======================

        const subscribe: any = {
          request: "join",
          room: this.room,
          ptype: "subscriber",
          feed: id,
          private_id: this.my_private_id,
          offer_video: void 0
        };

        // if (Janus.webRTCAdapter.browserDetails.browser === 'safari' &&
        //   (video === 'vp9' || (video === 'vp8' && !Janus.safariVp8))) {
        //   if (video) {
        //     video = video.toUpperCase();
        //   }
        //   this.toastrService.warning('Publisher is using ' + video + ', but Safari doesn\'t support it: disabling video');
        //   subscribe.offer_video = false;
        // }

        remoteFeed.videoCodec = video;
        remoteFeed.send({ message: subscribe });
        this.roomStateService.updateFeeds(remoteFeed);
        console.groupEnd();
      },
      error: (error: Error) => {
        console.group('Error');
        this.toastrService.error("-- Error attaching plugin...");
        console.error(error);
        console.groupEnd();
      },
      onmessage: (msg: any, jsep: any) => {
        // console.group('Subscriber OnMessage');
        // Janus.log("%c::: Got a message (subscriber) :::", 'color:silver;background:darkred;', msg, "remoteFeed =>",
        //   );
        // console.log("%cjsep =>", 'color:silver;background:darkred;', jsep);

        // this._isAnyoneVideoStreaming$.next(this.checkVideoStreaming()); // todo need optimisation
        const event: "attached" | "event" = msg?.videoroom;
        const control: string = msg?.control;

        if (msg?.error) {
          console.error("ERROR", msg.error);
          this.toastrService.error(msg.error);
        } else if (event || control) {
          // console.log("EVENT ==>", event);
          switch (event) {
            case "attached":
              // console.log("attached eevent ==>>", event);
              this.grabFeed(remoteFeed, msg); //todo двойное обновление фидов случай №1
              Janus.log(`Successfully attached to feed ${remoteFeed.rfid} (${remoteFeed.rfdisplay}) in room ${msg["room"]}`);
              this.roomStateService.updateFeeds(remoteFeed); //todo двойное обновление фидов случай №2
              break;

            case "event":

              if ((msg?.substream !== null && msg?.substream !== undefined) ||
                (msg?.temporal !== null && msg?.temporal !== undefined)) {
                if (!remoteFeed.simulcastStarted) {
                  remoteFeed.simulcastStarted = true;
                  this.roomStateService.updateFeeds(remoteFeed);
                }
              } else {
                this._someVideoStateChanges$.next(remoteFeed); // удалённый фид начал/прекратил трансляцию видео
              }
              break;

            default:
              // console.log("not recognized event type => ", event);
              break;
          }

          if (control) {
            this.controlHandler(msg);
          }

          if (jsep) {
            Janus.log("Handling SDP as well...", jsep);

            remoteFeed.createAnswer(
              <any>{
                jsep: jsep,

                // Add data:true here if you want to subscribe to datachannels as well
                // (obviously only works if the publisher offered them in the first place)
                media: { audioSend: false, videoSend: false },

                success: (jsep_success: any) => {
                  Janus.log("Got SDP!", jsep_success);
                  const body = { request: "start", room: this.room };
                  remoteFeed.send({ message: body, jsep: jsep_success });
                },

                error: (error: Error) => {
                  Janus.error("WebRTC error:", error);
                  this.toastrService.error(`WebRTC error...${error.message}`);
                }
              });
          }
        }

        // console.groupEnd();
      },
      iceState: (state: any) => {
        Janus.log("ICE state of this WebRTC PeerConnection (feed #" + remoteFeed.rfindex + ") changed to " + state, 'color: blue;background:aqua');
      },
      webrtcState: (on: any) => {
        Janus.log("%cJanus says this WebRTC PeerConnection (feed #" + remoteFeed.rfindex + ") is " + (on ? "up" : "down") + " now", 'color: green;background:silver');
      },
      onlocalstream: (stream: any) => {
        Janus.log("%c-== SUBSCRIBER ==- onlocalstream stream", 'color:brown;background:pink;', stream);
        // The subscriber stream is recvonly, we don't expect anything here
      },
      onremotestream: (stream: any) => {
        console.group('OnRemoteStream');
        Janus.log("%c-== SUBSCRIBER ==- onremotestream stream", 'color:yellow;background:cadetblue;', stream);
        Janus.log("%cRemote feed #" + remoteFeed.rfindex + ", 'color:yellow;background:cadetblue;', stream:", stream);

        stream.getTracks().forEach((track: MediaStreamTrack, index: number) => {
          Janus.log(`track #${index} =>`, track);
          remoteFeed.mediaStream.addTrack(track);
        });

        const videoTracks = stream.getVideoTracks();
        const hasVideo = videoTracks && videoTracks.length;

        remoteFeed.rfparsed = JSON.parse(remoteFeed.rfdisplay);
        remoteFeed.rfclient = remoteFeed.rfparsed.client;

        Janus.log("remoteFeed ===> ", remoteFeed);
        Janus.log("remoteFeed.rfclient ===> ", remoteFeed.rfclient);
        if (remoteFeed.handleStream) {
          remoteFeed.video.hasVideo = hasVideo;
          this.addToContainer(remoteFeed.rfclient, remoteFeed.video);
          // Janus.attachMediaStream(remoteFeeds[id].video, stream)
          return;
        }
        remoteFeed.handleStream = true;
        Janus.log("Remote feed #" + remoteFeed.rfid + ", stream:", stream);
        // console.log("DDD", remoteFeed.rfid, stream.getAudioTracks().length, stream.getVideoTracks().length, stream);

        if (!tag) {
          console.error("no tag passed");
        }

        remoteFeed.video = {
          id,
          el: tag,
          rfid: remoteFeed.rfid,
          info: remoteFeed.rfdisplay,
          volume: 0,
          hasVideo
        };

        this.addToContainer(remoteFeed.rfclient, remoteFeed.video);

        setTimeout(() => {
          console.log('onremotestream: attach Janus.attachMediaStream for remoteFeed =>', remoteFeed);
          Janus.attachMediaStream(remoteFeed.video.el, remoteFeed.mediaStream);
        }, 0);

        remoteFeed.audioSource = remoteFeed.audioContext.createMediaStreamSource(stream);
        remoteFeed.scriptNode = remoteFeed.audioContext.createScriptProcessor(2048, 1, 1);

        remoteFeed.scriptNode.onaudioprocess = (event: any) => {
          const { volume } = this.onAudioProcess(event);
          remoteFeed.volume = volume;
          this._feeds_volumes$.next({ rfindex: remoteFeed.rfindex, volume: volume, rfid: remoteFeed.rfid });
          // Janus.log(`== feed #${remoteFeed.rfindex} volume ==`, remoteFeed.volume);
        };

        remoteFeed.audioSource.connect(remoteFeed.scriptNode);
        remoteFeed.scriptNode.connect(remoteFeed.audioContext.destination);
        this.roomStateService.updateFeeds(remoteFeed);
        console.groupEnd();
      },
      oncleanup: () => {
        console.group('OnCleanUp');
        Janus.log("%c::: Got a cleanup notification (remote feed " + id + ") :::", 'color:cadetblue;background:yellow;');
        remoteFeed.audioSource.disconnect();
        remoteFeed.scriptNode.disconnect();
        this.deleteFromContainer(remoteFeed.rfclient, remoteFeed.rfid);
        this.roomStateService.deleteFeeds([remoteFeed]);
        console.groupEnd();
      },
      handleEvent: (json: any, skipTimeout: any) => {
        Janus.log("handleEvent => json", json, " skipTimeout => ", skipTimeout);
      }
    });
  }

  /**
   * дополнить и сохранить feed
   * @param remoteFeed
   * @param msg
   */
  private grabFeed(remoteFeed: IFeed, msg: any): void {
    // console.log("grabFeed invoked, remoteFeed=>", remoteFeed, "msg =>", msg);
    remoteFeed.rfid = msg?.id;
    remoteFeed.rfdisplay = msg?.display;

    // for (let i = 1; i <= this.MAX_PUBLISHERS; i++) { // найти свободный видеотег для нового публишера и сохранить его feed
    //   if (!this.feeds.has(i)) {
    //     remoteFeed.rfindex = i;
    //     this.feeds.set(i, remoteFeed);
    //     this._feeds_updated.next(this.feeds); // оповестить все компоненты что список фидов поменялся
    //     Janus.log(`remoteFeed with rfid: ${remoteFeed.rfid}, display: ${remoteFeed.rfdisplay} grab key: ${i}`);
    //     break;
    //   }
    // }

    this.feeds.set(remoteFeed.rfid, remoteFeed);
    this._feeds_updated.next(this.feeds); // оповестить все компоненты что список фидов поменялся
    Janus.log(`remoteFeed with rfid: ${remoteFeed.rfid}, display: ${remoteFeed.rfdisplay} grab key by id: ${remoteFeed.rfid}`);


    this.checkForDuplicates(remoteFeed);
  }

  releaseFeed(id: number): boolean {
    return this.feeds.delete(id);
  }

  private releaseFeedByPublisher(publisher_id: number, isDeepClear: boolean = false): number {
    Janus.log("releaseFeedByPublisher invoked, publisher_id =>", publisher_id);
    Janus.log("this.feeds =>", this.feeds);

    for (const [key, value] of this.feeds.entries()) {
      if (value.rfid === publisher_id) {
        value.detach({});
        if (isDeepClear) {
          this.usersInRoom[value.rfparsed.user.id] = false;
          this.invites[value.rfparsed.user.id] = {};
        }
        if (this.releaseFeed(key)) {
          this._feeds_updated.next(this.feeds);
          Janus.log("FEED MODS => releaseFeed success by index: ", key);
          return key;
        } else {
          console.warn("releaseFeed fail by index: ", key);
        }
      }
    }
    return -1;
  }

  /**
   * Return free index of feeds
   */
  // getEmptyFeedIndex(): number {
  //   let free = -1;
  //
  //   // tslint:disable-next-line
  //   for (let i = 1; i <= this.MAX_PUBLISHERS; i++) {
  //     if (!this.feeds.has(i)) {
  //       free = i;
  //       break;
  //     }
  //   }
  //   return free;
  // }


  private makeUrl(accessToken: string): string {
    const at = accessToken.split(" ")[1];
    const wsDomain = this.appConfigService.appConfig.wsDomain ? this.appConfigService.appConfig.wsDomain : this.document.location.host;
    // console.log("wsDomain =>", wsDomain);
    return `wss://${wsDomain}/api/remote-expert2/ws?client=desktop&version=0.0.1&deviceSerial=ad20201&accessToken=${at}`;
  }

  /**
   * Public function for attach videoroom plugin
   */
  attachVideoroomPlugin() {
    Janus.log("attachVideoroomPlugin invoke ==>");

    if (!this.janus) {
      console.error("janus instance is not found");
      return;
    }
    // this.myVideo = myVideo;
    this.janus.attach({
      plugin: "janus.plugin.videoroom",
      opaqueId: this.opaqueId,
      success: (pluginHandle: PluginHandle) => {
        Janus.log("%c-== Self Attach pluginHandle (this.sfutest) ==-", 'color: white; background:black;', pluginHandle);
        this.sfutest = pluginHandle;

        // console.log("MUTE this.isMuteState =>", this.isMuteState);
        // console.log("MUTE !this.sfutest.isAudioMuted() =>", !this.sfutest.isAudioMuted());
        // console.log("MUTE this.myStream 1", this.myStream);

        if (this.isMuteState !== undefined) {
          setTimeout(() => {
            this.toggleMute(this.isMuteState);
            // console.log("MUTE this.myStream 2", this.myStream);
          }, 2500);
        } else {
          this._muted.next(!this.sfutest.isAudioMuted()); // hack
        }

        this._isSfuTestObtained.next(true);
        this._isSfuTestObtained.complete();
      },
      error: (cause: any) => {
        // Couldn't attach to the plugin
        console.error("error", cause);
      },
      consentDialog: (on: any) => {
        Janus.log("Consent dialog should be " + (on ? "on" : "off") + " now");
        if (on) {
          // console.log("Darken screen and show hint");
        } else {
          // console.log("Restore screen");
        }
      },
      iceState: (state: any) => {
        Janus.log("ICE state changed to " + state);
      },
      mediaState: (medium: any, on: any) => {
        Janus.log("Janus " + (on ? "started" : "stopped") + " receiving our " + medium);
        if (medium === "video") {
          this.sharedScreen = on;
        }
      },
      webrtcState: (on: any) => {
        Janus.log("Janus says our WebRTC PeerConnection is " + (on ? "up" : "down") + " now");
        if (on) {
          this.joinStatus = "joined";
        } else {
          this.joinStatus = "leaved";
        }
      },
      onmessage: (msg: any, jsep: any) => {
        // console.group('Publisher OnMessage');
        // console.log("%c::: Got a message (publisher) :::", 'color: gold;background: indigo;' , msg);
        // We got a message/event (msg) from the plugin
        // If jsep is not null, this involves a WebRTC negotiation
        // console.log("%cjsep =>", 'color: gold;background: indigo;' , jsep);

        const event: "joined" | "destroyed" | "event" = msg.videoroom;

        switch (event) {
          case "joined":
            this.my_id = msg.id;
            this.my_private_id = msg.private_id;
            this.publishOwnFeed();
            // первоначально получает список всех
            // кто в комнате
            this.getPublishers(msg);
            break;

          case "destroyed":
            Janus.log("videoroom destroyed event");
            break;

          case "event":
            // присылает только вновь подключившегося
            if (msg.publishers) {
              Janus.log("%cVideoroom event msg.publishers", 'color: green; background:pink;');
              // Any new feed to attach to?
              // this.getPublishers(msg);
              this.newPublisherConnected(msg);
            } else if (msg.leaving) {
              Janus.log("%cVideoroom event msg.leaving", 'color: blue; background:pink;', msg.leaving);

              if (msg.leaving === "ok") { // если локальный юзер вышел из комнаты
                this.joinStatus = "leaved";
                this.cleanStreams();
                this.sfutest.hangup();
                this.attachVideoroomPlugin();
                return;
              }

              // One of the publishers has gone away?
              this.publisherLeaving(msg.leaving);

            } else if (msg.unpublished) {
              Janus.log("%cVideoroom event msg.unpublished", 'color: silver; background:pink;', msg.unpublished);
              // One of the publishers has unpublished?

              if (msg.leaving === "ok") { // если локальный юзер закрыл вкладку
                this.joinStatus = "leaved";
                this.cleanStreams();
                this.sfutest.hangup();
                this.attachVideoroomPlugin();
                return;
              }

              this.publisherUnpublished(msg.unpublished);

            } else if (msg.error) {
              // fail
              Janus.log("videoroom event msg.error", msg.error);
            }
            break;
        }

        if (msg?.control) {
          this.controlHandler(msg);
        }

        if (jsep) {
          Janus.log("Handling SDP as well...", jsep);
          this.sfutest.handleRemoteJsep({ jsep });

          // Check if any of the media we wanted to publish has
          // been rejected (e.g., wrong or unsupported codec)

          const audio = msg?.audio_codec;

          if (this.myStream && this.myStream.getAudioTracks() && this.myStream.getAudioTracks().length > 0 && !audio) {
            this.toastrService.warning("No audio tracks");
          }

          const video = msg?.video_codec;

          if (this.myStream && this.myStream.getVideoTracks() && this.myStream.getVideoTracks().length > 0 && !video) {
            this.toastrService.warning("No video tracks");
          }

        }
      },
      onlocalstream: (stream: any) => {
        Janus.log(" ::: Got a local stream :::", stream);
        this.myStream = stream;
        this.sfutest.webrtcStuff.myStream = this.myStream;
        // console.log('this.myStream =>', this.myStream);
        // console.log('this.myvideo =>', this.myVideo);

        this._myStream$.next(stream);


        if (this.sfutest?.webrtcStuff.pc.iceConnectionState !== "completed" && this.sfutest?.webrtcStuff.pc.iceConnectionState !== "connected") {
          // console.log("not completed/connected");
        }

        const videoTracks = stream.getVideoTracks();

        if (!videoTracks || videoTracks.length === 0) {
          // console.log("not video tracks");
        }

        this.local.audioContext = new (window as any).AudioContext();
        // console.log('this.local.audioContext ==>', this.local.audioContext);
        const at = stream.getAudioTracks();
        // console.log("stream audiotracks ==>", at);
        if (at.length) {
          this.local.audioSource = this.local.audioContext.createMediaStreamSource(stream);
          // console.log('this.local.audioSource ==>', this.local.audioSource);
          this.local.scriptNode = this.local.audioContext.createScriptProcessor(2048, 1, 1);

          this.local.scriptNode.onaudioprocess = this.onAudioProcessScript.bind(this);

          this.local.audioSource.connect(this.local.scriptNode);
          this.local.scriptNode.connect(this.local.audioContext.destination);
        }
        // console.log('this.local.scriptNode ==>', this.local.scriptNode);

        // this.local.audioAnalyser = this.local.audioContext.createAnal4yser();

        // console.log('this.local.audioAnalyser ==> ', this.local.audioAnalyser.addEventListener('audio', (ev) => console.log('aaaaaa', ev)));

      },
      onremotestream: (stream: any) => {
        // The publisher stream is send only, we don't expect anything here
      },
      detached: () => {
        // Connection with the plugin closed, get rid of its features
        // The plugin handle is not valid anymore
        Janus.log("videoroom plugin detached");
      },
      oncleanup: () => {
        Janus.log(" ::: Got a cleanup notification: we are unpublished now :::");
        this.myStream = null;
        this.sfutest.webrtcStuff.myStream = this.myStream;

        this.local.audioSource?.disconnect();
        this.local.scriptNode?.disconnect();
      }
    });
  }

  onAudioProcessScript(event: any) {
    const { volume } = this.onAudioProcess(event);
    this.local.volume = volume;
    this._localVolume$.next(this.local.volume);
    // console.log('== current volume ==', this.local.volume);
  }

  /**
   * processing control messages (proxy)
   * @param msg
   */
  private controlHandler(msg: any) {
    // console.log("CONTROL", msg);

    switch (msg["control"]) {

      case "rooms":
        this.rooms = msg.rooms;
        if (!this.uid && this.accepted_room_id) {
          const uid = this.rooms?.find((room: any) => room.id === this.accepted_room_id)?.uid;
          this.uid = uid ? uid : this.uid;
          // console.log("case \"rooms\" this.uid =>", this.uid, "this.rooms =>", this.rooms);
        }
        this._rooms$.next(this.rooms);
        break;
      case "roomsOnline":
        this.roomsOnline = msg.roomsOnline;
        this._roomsOnline$.next(this.roomsOnline);
        if (!this.uid && this.accepted_room_id) {
          const uid = this.roomsOnline?.find((room: any) => room.id === this.accepted_room_id)?.uid;
          this.uid = uid ? uid : this.uid;
          // console.log("case \"roomsOnline\" this.uid =>", this.uid, "this.roomsOnline =>", this.roomsOnline);
        }
        break;
      case "user":
        this.profile.fullName = msg.user.fullName;
        this._profile$.next(this.profile);
        break;
      case "users":
        this.online = msg.users;
        this.commonStateService.updateCallCandidates(msg.users);
        break;
      case "online":
        // this.online = msg.user;
        // this._online$.next(this.online);
        this.commonStateService.updateOneCallCandidate(msg.user);
        break;
      case "incomingCall":
        this.onIncomingCall(msg);
        break;
      case "incomingCallAcked":
        this.onIncomingCallAcked(msg);
        break;
      case "incomingCallRejected":
        this.onIncomingCallRejected(msg);
        break;
      case "incomingCallCancelled":
        this.onIncomingCallCancelled(msg);
        break;
      case "callJoin":
        this.onCallJoin(msg);
        break;
      case "incomingCallAccepted":
        this.onIncomingCallAccepted(msg);
        break;
      case "invitation":
        this.onInvitation(msg);
        break;
      case "inviteAcked":
        this.onInviteAcked(msg);
        break;
      case "otherInvite":
        this.onOtherInvite(msg);
        break;
      case "inviteAccepted":
        this.onInviteAccepted(msg);
        break;
      case "inviteRejected":
        this.onInviteRejected(msg);
        break;
      case "chatMessage":
        this.receiveChatMessage(msg);
        break;
      case "takePictureRequest":
        this.onPictureRequest(msg);
        break;
      case "takePictureContent":
        this.onPictureContent(msg);
        break;
      case "takePictureAcked":
        this.onTakePictureAcked(msg);
        break;
      case "takePictureAccepted":
        this.onTakePictureAccepted(msg);
        break;
      case "takePictureRejected":
        this.onTakePictureRejected(msg);
        break;
      case "takePictureError":
        this.onTakePictureError(msg);
        break;
      default:
        console.log("Unknown control msg", msg);
    }

  }

  onIncomingCall(msg: any) { //todo make private
    // console.log("onIncomingCall invoking =>", msg);
    this.roomStateService.updateIsGuardActive(false);
    this.uid = msg.uid;
    if (msg.from.id === this.callerId) {

      const callAccept = {
        control: "callAccept",
        uid: msg.uid
      };

      if (this.router.url.includes("main/conference/room")) {
        this.router.navigateByUrl(`/main/conference/conference-list`)
          .then((answer) => {
            // if (answer) {
            (this.sfutest as any).send({ message: callAccept });
            // }
          })
          .catch((e) => console.error(e));
        // this.router.navigateByUrl(`/main/conference/room/accepted_call`);
      } else {
        (this.sfutest as any).send({ message: callAccept });
      }


    } else {
      this.bellPlay();
      const title = "";
      const text = `<div> ${msg.from?.fullName} </div>`;
      // console.log("onIncomingCall this.uid =>", this.uid);
      const activeToast = this.toastrService.info(text, title, {
        toastComponent: ToastrCallDialogComponent,
        closeButton: true,
        enableHtml: true,
        timeOut: 0,
        tapToDismiss: false,
        messageClass: "",
        positionClass: "my-top-center"
      });
      activeToast.portal.instance.setData(msg, this.sfutest, this.join.bind(this), this.bell);
    }
  }

  private onIncomingCallAcked(msg: any) {
    this.bellStop();
    this.roomStateService.updateIsGuardActive(true);
    if (this.initCall.uid === msg.uid) {
      this.initCall.ack = true;
    }
  }

  private onIncomingCallRejected(msg: any) {
    // console.log("========onIncomingCallRejected======", msg);
    this.toastrService.warning(msg.from.fullName, `Call rejected`);
    this.initCall = { ack: undefined, uid: undefined, user: undefined };
    this._onIncomingCallRejected$.next(true);
    this._onIncomingCallRejected$.next(false); // last value must always false
  }

  private onIncomingCallCancelled(msg: any) {
    // console.log("========onIncomingCallCancelled======", msg);
    this.bell.pause();
    this.roomStateService.updateIsGuardActive(true);
    this.toastrService.error("The user rejected the call");
    this._onIncomingCallCancelled$.next(true);
    this._onIncomingCallCancelled$.next(false); // last value always must be false!!!
    // toasts[message.uid] && toasts[message.uid].remove();
    // delete toasts[message.uid];
  }

  private onCallJoin(msg: any) {
    // console.log("========onCallJoin======", msg);
    this.initCall = {};
    this.join(msg.room);
    this.router.navigateByUrl(`/main/conference/room/${msg.room}`).catch(console.error);
  }

  private onIncomingCallAccepted(msg: any) {
    // console.log("========onIncomingCallAccepted======", msg);
    this.bellStop();
    this.initCall = {};
    this.join(msg.room);
    // console.log("this.activeToast", this.activeToast);
    // this.activeToast.remove();
    this.toastrService.remove(this.activeToast.toastId);
    this.router.navigateByUrl(`/main/conference/room/${msg.room}`).catch(console.error);
  }

  setRoomNumber(roomNumber: number) {
    this.room = +roomNumber;
    // console.log("set room numbet to ", this.room);
  }

  onInvitation(msg: any) { //todo make private
    // console.log("onInvitation msg =>", msg);
    // bell.play()
    const title = "";
    const innerHTML = `<div class="pr-2">${this.invite_call}</div> <div>${msg.from?.fullName}</div>`;
    this.uid = msg.uid;
    // console.log("onInvitation this.uid =>", this.uid);
    const activeToast = this.toastrService.info(innerHTML, title, {
      toastComponent: ToastrInviteDialogComponent,
      closeButton: true,
      enableHtml: true,
      timeOut: 0,
      tapToDismiss: false,
      messageClass: "",
      positionClass: "my-top-center"
    });
    // activeToast.portal.instance.setData(msg, this.sfutest, this.join.bind(this));
    activeToast.portal.instance.setData(msg, this.sfutest, this.bell);
  }

  private onInviteAcked(msg: any) {
    // console.log("========onInviteAcked======", msg);
    this.invites[msg.from.id] = this.invites[msg.from.id] || {};
    this.invites[msg.from.id].delivered = true;
  }

  private onOtherInvite(msg: any) {
    // console.log("========onOtherInvite======", msg);
    this.invites[msg.user.id] = this.invites[msg.user.id] || {};
    this.invites[msg.user.id].otherUsers = this.invites[msg.user.id].otherUsers || "";
    this.invites[msg.user.id].otherUsers += msg.from.fullName;
  }

  private onInviteAccepted(msg: any) {
    // console.log(`${msg.from.fullName} Приглашение принято`);
    this.invites[msg.from.id] = {};
    this.toastrService.info(msg.from.fullName, "Invitation accepted");
    this._onInviteAccepted$.next(msg.from);
  }

  private onInviteRejected(msg: any) {
    // console.log(`${msg.from.fullName} Приглашение отклонено`);
    this.invites[msg.from.id] = {};
    this.toastrService.warning(msg.from.fullName, "Invitation declined");
    this._onInviteRejected$.next(msg.from);
  }

  private join(roomNumber: number) {
    // console.log("========join====== roomNumber => ", roomNumber);
    this.callStore.autoJoin = true;
    this.callStore.currentRoom = roomNumber;
    this.room = +roomNumber;
    this.usersInRoom = {};

    const register = {
      request: "join",
      room: +roomNumber,
      ptype: "publisher",
      display: "{}"
    };

    this.sfutest.send({ message: register });
  }

  /**
   *
   * @param client - type of client ('mobile' | 'desktop')
   * @param video - video descriptor
   */
  private addToContainer(
    client: any,
    video: {
      id: number,
      el: any,
      rfid: number,
      info: string,
      volume: number,
      hasVideo: boolean
    }
  ) {
    // console.log("addToContainer client", client);
    if (!client || client === "undefined") {
      console.error("no client passed into addToContainer");
      return;
    }

    const index = this.containers[client]?.findIndex((v: any) => v.rfid === video.rfid);

    if (index !== -1) {
      this.containers[client][index] = video;
      Janus.log("this.containers => ", this.containers);
      return;
    }

    this.containers[client] = this.containers[client].concat([video]);
    Janus.log("this.containers => ", this.containers);
  }

  private deleteFromContainer(client: any, rfid: any) {
    // console.log("deleteFromContainer client", client);
    this.containers[client] = this.containers[client]?.map((v: any) => v.rfid === rfid ? {} : v);
  }

  private sendIncomingCallAck(msg: any) {
    // console.log("========sendCallAck======", msg);
    const callAck: any = {
      control: "callAck",
      uid: msg.uid,
      request: undefined // todo check stable after this
    };

    this.sfutest.send({ message: callAck });
  }

  toggleMute(forceMute?: boolean) {
    if (forceMute) {
      // console.log("MUTE forceMute =>", forceMute);
      // forceMute ? this.sfutest.unmuteAudio() : this.sfutest.muteAudio(); //WHF?? is inverted
      forceMute ? this.sfutest.muteAudio() : this.sfutest.unmuteAudio(); //WHF?? is inverted
    } else {
      this.sfutest.isAudioMuted() ? this.sfutest.unmuteAudio() : this.sfutest.muteAudio();
    }
    this.isMuteState = this.sfutest.isAudioMuted(); // save state of MUTE for rejoin
    const result = this.sfutest.isAudioMuted();
    // console.log("MUTE result =>", result);
    this._muted.next(result);
  }

  /**
   * Очищает структуры которые по всей видимости не используются (НУЖНО ПРОВЕРИТЬ И ОТРЕФАКТОРИТЬ)
   * @private
   */
  private cleanStreams() {
    this.containers.mobile = [];
    this.containers.desktop = [];
    this.invites = {};
    // this.feeds.forEach((value) => {
    //   // console.log("feed", value.detach({}));
    // });
    // for (const [key, value] of this.feeds.entries()) {
    // }
  }

  destroyJanus() {
    // console.log("-== TRY TO DESTROY JANUS ==-");
    if (!this.janus) {
      console.warn("no janus instance, return");
      return;
    }
    this.leave();
    this.cleanStreams();
    // this.janus = null;
    // this.clear();
    // console.log('<=== Janus DESTROYED ===>');

    this.janus.destroy({
      success: () => {
        this.janus = null;
        this.clear();
        // console.log("<=== Janus DESTROYED ===>");
      }
    });
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  leaveRoom() {
    console.group("leaveRoom RoomComponent");
    console.log("== leaveRoom ==");
    this.clear();
    this.cleanStreams();
    this.publishers = [];
    this.leave();
    console.groupEnd();
  }

  joinToRoom(roomNumber: number = 1) {
    // console.log("joinToRoom ==> ", roomNumber);
    if (!this.sfutest) {
      console.error("this.sfutest not init");
      return;
    }
    this.join(roomNumber);
  }

  attachVideoTag(el: ElementRef) {
    if (!this.myStream) {
      console.error("this.myStream is: ", this.myStream);
      return;
    }
    this.myVideo = el;
    setTimeout(() => {
      Janus.attachMediaStream(el?.nativeElement, this.myStream);
    }, 0);
  }

  saveAttach(el: ElementRef) {
    // console.log("saveAttach el =>", el);
    this.video_tag = el;
    // console.log("saveAttach this.video_tag =>", this.video_tag);
  }

  videoAttach(feed?: IFeed) {
    if (feed) {
      this.nowInAttachSaveFeed = feed;
      this.reattachSharedScreenMediaStream(this.nowInAttachSaveFeed, this.video_tag);
    } else {
      this.reattachSharedScreenMediaStream(this.nowCenteredFeed, this.video_tag);
    }
  }

  reattachSharedScreenMediaStream(remoteFeed: any, el: ElementRef) {
    // console.log("reattachSharedScreenMediaStream remoteFeed ==>", remoteFeed);
    this.nowCenteredFeed = remoteFeed;
    this.elSharedScreen.pop(); // todo need clear procedure
    // if (this.elSharedScreen.length) {
    //   console.log('== sharescreen window is busy ==');
    //   return;
    // }

    this.elSharedScreen.push(el);
    // remoteFeed.video.el = el;

    if (el && remoteFeed) {
      setTimeout(() => {
        Janus.attachMediaStream(el.nativeElement, remoteFeed.mediaStream);
      }, 0);
      // console.log("this.elSharedScreen", this.elSharedScreen);
    } else {
      console.error('not enough data for attachMediaStream');
    }
  }

  reattachMediaStreamFormTo(to: HTMLVideoElement, from: HTMLVideoElement) {
    Janus.reattachMediaStream(to, from);
  }

  attachMediaStreamToTag(remoteFeed: any, tag: HTMLVideoElement, forceReattach = false) {
    console.log("Janus attachMediaStreamToTag =>", Janus);
    console.log('Janus attachMediaStreamToTag remoteFeed =>', remoteFeed);
    console.log('Janus attachMediaStreamToTag tag =>', tag);
    console.log('Janus attachMediaStreamToTag forceReattach =>', forceReattach);

    if (remoteFeed && tag) {
      setTimeout(() => {
        if (forceReattach) {
         // Janus.reattachMediaStream(toTag, fromTag);
          console.error('Not implemented');
        } else {
          Janus.attachMediaStream(tag, remoteFeed.mediaStream);
        }
      }, 0);
    } else {
      console.error("not enough arguments");
    }
  }


  get isShareScreenBusy(): boolean {
    return !!this.elSharedScreen.length;
  }

  releaseSharedScreen() {
    // console.log("releaseSharedScreen invoked");
    // this.elSharedScreen[0]
    this.elSharedScreen.pop();
    this._releaseSharedScreen$.next(null);
  }

  private clear() {
    this.publishers = [];
    this.feeds = new Map<number, any>();

    this._feeds_updated.next(this.feeds);
    this._feeds_volumes$.next(null);
    this._publishers$.next(this.publishers);
  }

  /**
   * Послать сигнал на сервер Janus о выходе из комнаты
   * @private
   */
  private leave() {
    if (!this.sfutest) {
      console.error("leave error, no this.sfutest");
      return;
    }

    this.sfutest.send({ message: { request: "leave", room: this.room } });
  }

  makeCall(id?: number, roomUID?: string) {
    if (!id || id < 0) {
      console.error('no user id passed');
      return;
    }
    this.callerId = id;
    const user: IJanusUser = {
      id,
      fullName: "проверка связи",
      roles: []
    };

    const title = "";
    const innerHTML = `<div>${this.callingLabel}</div>`;
    this.activeToast = this.toastrService.info(innerHTML, title, {
      toastComponent: ToastrDoCallDialogComponent,
      closeButton: true,
      enableHtml: true,
      timeOut: 0,
      tapToDismiss: false,
      messageClass: "",
      positionClass: "my-top-center"
    });
    this.call({ id, fullName: "проверка связи", roles: [] }, roomUID);
    // console.log("this.initCall uid check ==>", this.initCall);
    this.activeToast.portal.instance.setData(this.initCall.uid, user, this.sfutest, this.callCancelation.bind(this));
  }

  private call(user: IJanusUser, roomUID?: string) {
    // console.log("-----call", user);
    // console.log("call roomUID", roomUID);
    this.uid = roomUID ? roomUID : uid(64); // todo SAVE FOR INVITE link
    const call: any = {
      control: "call",
      uid: this.uid,
      user,
      request: undefined // todo check stable after this
    };

    // console.log("call =>>", call);

    this.initCall = {
      user: user,
      uid: call.uid,
      name: user.fullName
    };

    // console.log("this.initCall =>>", this.initCall);

    this.sfutest.send({ message: call });
  }

  private callCancelation(call: any) {
    // console.log("<== callCancelation invoked ==>", call);
    const callCancel: any = {
      control: "callCancel",
      user: call.user,
      uid: call.uid,
      request: undefined // todo check stable after this
    };

    this.initCall = {};

    this.sfutest.send({ message: callCancel });
  }


  inviteUserToCurrentRoom(user: IJanusUser) {
    this.invite(user);
  }

  private invite(user: any) {
    // console.log("-----invite--", user);

    const invite: any = {
      control: "invite",
      uid: this.uid,
      room: +this.room,
      user,
      request: undefined
    };

    this.sfutest.send({ message: invite });
  }

  bellPlay(): void {
    this.bell?.play();
  }

  bellStop(): void {
    // console.log("bell stop invoking ===>>");
    this.bell?.pause();
  }

  shareCanvas(canvas: any | HTMLCanvasElement) {
    console.group("shareCanvas");

    // console.log("this.isShareCanvasAlready ==>", this.isShareCanvasAlready);
    if (this.isShareCanvasAlready) {
      // console.log("canvas share is locked, return");
      console.groupEnd();
      return;
    }
    this.isShareCanvasAlready = true;

    this.canvasStream = canvas.captureStream(25);
    const ct = this.canvasStream.getTracks();
    // console.log("canvas tracks is:", ct);
    // console.log("this.myStream.getAudioTracks() =>", this.myStream.getAudioTracks());
    // this.canvasStream.addTrack(this.myStream.getAudioTracks()[0]);


    // this.canvasStream.addTrack(this.sfutest.webrtcStuff.myStream.getAudioTracks()[0]);


    this.logSfutest("before add tracks");

    // const alreadyAddedTracks = this.sfutest?.webrtcStuff?.myStream?.getVideoTracks().map(track => track.id);


    this.canvasStream.getVideoTracks().forEach((track: MediaStreamTrack) => {
      // console.log("addable track =>", track);

      // if (alreadyAddedTracks.some(id => id === track.id)) {
      //   return;
      // }

      this.sfutest?.webrtcStuff?.myStream?.addTrack(track);
      this.canvasStreamSender = this.sfutest.webrtcStuff.pc.addTrack(track, this.canvasStream);
    });

    this.logSfutest("after add tracks");

    this.publishOwnFeed({ capture: true, canvas: true });
    console.groupEnd();
  }

  stopShareCanvas() {
    console.group("stopShareCanvas");

    // console.log("this.isShareCanvasAlready ==>", this.isShareCanvasAlready);
    if (!this.isShareCanvasAlready) {
      // console.log("canvas share is NOT locked, return");
      console.groupEnd();
      return;
    }
    this.isShareCanvasAlready = false;

    // if (!this.canvasStreamSender) {
    //   return;
    // }

    this.logSfutest("before remove tracks");

    this.canvasStream.getVideoTracks().forEach((track: MediaStreamTrack) => {
      // console.log('removable track =>', track);
      this.sfutest?.webrtcStuff?.myStream?.removeTrack(track);
      this.sfutest?.webrtcStuff?.pc?.removeTrack(this.canvasStreamSender);
    });


    this.canvasStreamSender = null;

    this.publishOwnFeed({ capture: false, canvas: false });
    console.groupEnd();
  }

  private getMedia(options: any) {

    // console.log("==get=media==", options);

    if (options.capture && typeof options.canvas === "undefined") {
      // console.log("==share==");
      return {
        update: true,
        replaceVideo: true,
        video: "window",
        audioSend: true,
        videoRecv: false,
        videoSend: true
      };
    }

    // console.log("==xxx==");
    return {
      update: true,
      removeVideo: true,
      audioRecv: false,
      videoRecv: false,
      audioSend: true,
      videoSend: false,
      data: false
    };
  }


  private checkVideoStreaming(): boolean {
    let isNowAnyoneStreams = false;
    this.feeds.forEach((feed) => {
      if (feed?.video?.hasVideo && feed?.rfparsed?.client !== "mobile") {
        isNowAnyoneStreams = true;
      }
    });
    return isNowAnyoneStreams;
  }

  sendChatMessage(text: string) {
    const chatSendTextMessage: Partial<IControlChatSendMessageBody> = {
      control: "chatSendTextMessage",
      user: { id: this.my_id, fullName: this.username },
      uid: uid(),
      room: this.room,
      text
    };

    this.sfutest.send({ message: { ...chatSendTextMessage, request: null } });
  }

  sendChatVoiceMessage(buf: Blob, lang: any, rate: any) {
    // console.group("sendChatVoiceMessage");
    // console.log("buf ==>", buf);
    const reader = new FileReader();
    reader.readAsDataURL(buf);
    reader.onload = () => {
      const encode64 = reader.result?.toString();
      // console.log('encode64 ==>', encode64);
      const chopHeader = encode64?.substring("data:audio/wav;base64,".length);
      // console.log("chopHeader =>", chopHeader);


      const chatSendVoiceMessage: IControlChatSendVoiceMessageBody = {
        control: "chatSendVoiceMessage",
        user: { id: this.my_id, fullName: this.username },
        room: this.room,
        uid: uid(),
        rate,
        lang,
        audio: chopHeader || ''
      };

      this.sfutest.send({ message: { ...chatSendVoiceMessage, request: null } });
      this.roomStateService.doTerminateWorker(true);
    };

    // console.groupEnd();
  }

  sendChatVoiceMessageWav(uri: any, lang: any, rate: any) {
    // console.log('sendChatVoiceMessageWav uri =>', uri);
    const chopHeader = uri.substring("data:audio/wav;base64,".length);
    // console.log('sendChatVoiceMessageWav chopHeader =>', chopHeader);

    const chatSendVoiceMessage: IControlChatSendVoiceMessageBody = {
      control: "chatSendVoiceMessage",
      user: { id: this.my_id, fullName: this.username },
      room: this.room,
      uid: uid(),
      rate,
      lang,
      audio: chopHeader
    };

    this.sfutest.send({ message: { ...chatSendVoiceMessage, request: null } });
    this.roomStateService.doTerminateWorker(true);
  }

  private receiveChatMessage(msg: IControlChatMessageBody) {
    // console.log("receiveChatMessage msg ==> ", msg);
    this.roomStateService.updateChatMessages(msg);
  }

  setChatLanguage(chatLang: ChatLangs) {
    const body: IControlChatEnableTranslateBody = {
      control: "chatEnableTranslate",
      user: { id: this.my_id, fullName: this.username },
      room: this.room,
      uid: uid(),
      target: chatLang
    };

    this.sfutest?.send({ message: { ...body, request: null } });
  }

  // private onTakePictureError(msg: any) {
  //   this.toastrService.error(msg.message);
  //   this.reqPicLock = false;
  //   this._isImageLoadingProgress$.next(this.reqPicLock);
  // }

  /**
   * Запросить сделать скриншот
   */
  takePicture(user: any) {
    const takePic = {
      control: "takePicture",
      uid: uid(),
      room: this.room,
      user: user
    };
    // console.log(takePic);
    this.sfutest.send(<any>{ message: takePic });
    this._imageLoadingProgress$.next(0);
    this._isImageLoadingProgress$.next(true);
    setTimeout(() => {
      if (this.reqPicLock) {
        this.onTakePictureError({ message: "no response received within the specified time" });
      }
    }, 30000);
  }

  private onPictureRequest(msg: any) {
    // console.log("onPictureRequest ==>", msg);
    const info = `User ${msg.from.fullName} requested a screenshot`;
    this.toastrService.info(info);
    // const picAck = {
    //   control: 'takePictureAck',
    //   room: msg.room,
    //   uid: msg.uid,
    //   user: msg.from
    // };
    // this.sfutest.send({ message: picAck });
    //
    // const picAccept = {
    //   control: 'takePictureAccept',
    //   uid: msg.uid,
    //   room: msg.room,
    //   user: msg.from
    // };
    // console.log('pic accept send');
    // this.sfutest.send({message: picAccept});
  }

  /**
   * Отобразить скриншот на канве рисования
   * @param msg - Сообщение от Janus-сервера
   * @private
   */
  private onPictureContent(msg: any) {
    // console.log("onPictureContent msg ==>", msg);
    if (this.reqPicLock) { // пользователь сам нажал кнопку запроса скриншота
      // this._recivedImageSrc$.next(msg.url);
      this.roomStateService.updateReqScreenshotSrc(msg.url);
      this._isImageLoadingProgress$.next(false);
    } else {
      // console.log("!!screenshot not requested but recived!!");
    }
  }

  private onTakePictureAcked(msg: any) {
    this.toastrService.success(msg.from.fullName, "onTakePictureAcked");
  }

  private onTakePictureAccepted(msg: any) {
    this.toastrService.success(`${msg.percentage}%`, "onTakePictureAccepted");
    this._imageLoadingProgress$.next(msg.percentage);
  }

  private onTakePictureRejected(msg: any) {
    this.toastrService.success(msg.reason, "onTakePictureRejected");
    this.reqPicLock = false;
    this._isImageLoadingProgress$.next(this.reqPicLock);
  }

  private onTakePictureError(msg: any) {
    this.toastrService.error(msg.message);
    this.reqPicLock = false;
    this._isImageLoadingProgress$.next(this.reqPicLock);
  }

  private logSfutest(mark: any) {
    // console.log(mark);
    // console.log("this.sfutest.webrtcStuff.myStream.getTracks() ==>", this?.sfutest?.webrtcStuff?.myStream?.getTracks());
    // console.log("this.sfutest.webrtcStuff.pc.getSenders() ==>", this?.sfutest?.webrtcStuff?.pc?.getSenders());
  }

  setAcceptedRoomId(accepted_room_id: number) {
    this.accepted_room_id = accepted_room_id;
  }

  private checkForDuplicates(remoteFeed: IFeed) {
    console.group('checkForDuplicates');
    console.log('%ccheck for remoteFeed =>', 'color: indigo;background: violet', remoteFeed);
    const isDuplicateKey: number[] = [];
    // this.feeds.forEach((v, k) => {
    //   if(v.rfid === remoteFeed.rfid) {
    //     isDuplicateKey = k;
    //   }
    // })

    for (const pair of this.feeds.entries()) {
      if (pair[1].rfid === remoteFeed.rfid) {
        isDuplicateKey.push(pair[0]);
      }
    }


    if (isDuplicateKey.length > 1) {
      this.feeds.delete(isDuplicateKey[0]);
      // console.log("%cdelete isDuplicateKey =>", "color: indigo;background: violet", isDuplicateKey);
    }
    console.groupEnd();
  }

  private getIndexByUserId(id: number): number {
    // console.log("wipeSharedState getIndexByUserId id =>", id);
    const feedArrays = [...this.feeds.entries()];
    // console.log("wipeSharedState getIndexByUserId feedArrays =>", feedArrays);
    const match = feedArrays.find((arr) => arr[1].rfid === id);
    return match ? match[0] : -1;
  }
}
